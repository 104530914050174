import { request } from "@/utils/request"

export function createOrder(data) {
    return request({
        url: "api/advertising/tiktok/create",
        method: "post",
        data
    })
}
export function getListOrder(limit = null, type = null, objectId = null) {
    return request({
        url:
            "api/advertising/tiktok/list" +
            (limit ? "?limit=" + limit : "?limit=100") +
            (type ? "&type=" + type : "") +
            (objectId ? "&object_id=" + objectId : ""),
        method: "get"
    })
}

export function removeOrder(data) {
    return request({
        url: "api/advertising/tiktok/remove",
        method: "post",
        data
    })
}
